
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IUserService } from "@/services";

import { lightFormat } from "date-fns";
import { UserInfos } from "prometheus-synced-ui";

@Component
export default class ControllerAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  controller!: UserInfos | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  get userService() {
    return this.container.resolve<IUserService>(S.USER_SERVICE);
  }

  users: { id: string | null; fullName: string }[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.users = await this.userService.getMany({
        ids: [],
        includeMeasureAppUser: true,
        includeSuperAdministrator: false,
        includeAdministrator: false,
        includeDbUser: false,
        includeExamReportUser: false,
        includeNone: false,
        searchDate: lightFormat(new Date(), "yyyy-MM-dd"),
      });
      if (this.includeAll) {
        this.users.splice(0, 0, {
          id: null,
          fullName: "Tous",
        });
      }
    } finally {
      this.loading = false;
    }
  }

  onInput(controller: UserInfos) {
    this.$emit("update:controller", controller);
    this.$emit("input", controller.id);
  }
}

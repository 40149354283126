
import { Component, Prop, Vue } from "vue-property-decorator";

import ZoneFilter from "@/components/Core/ExamReports/Dashboard/ZoneFilter.vue";
import StatusFilter from "@/components/Core/ExamReports/Dashboard/StatusFilter.vue";
import ControllerAutocomplete from "@/components/General/Autocompletes/ControllerAutocomplete.vue";

@Component({
  components: {
    ZoneFilter,
    StatusFilter,
    ControllerAutocomplete,
  },
})
export default class DashboardFilter extends Vue {
  @Prop({ required: true })
  created!: boolean;

  @Prop({ required: true })
  assigned!: boolean;

  @Prop({ required: true })
  launched!: boolean;

  @Prop({ required: true })
  terminated!: boolean;

  @Prop({ required: true })
  canceledByAnalyst!: boolean;

  @Prop({ required: true })
  powerPlantId!: string | null;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  @Prop({ required: true })
  systemId!: string | null;

  @Prop({ required: true })
  controllerId!: string | null;
}


import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { MeasuringRunStatus, MEASURING_RUN_STATUSES_DISPLAY } from "@/domain";

@Component({
  data: () => {
    return {
      MeasuringRunStatus,
    };
  },
})
export default class StatusFilter extends Vue {
  @Prop({ required: true })
  created!: boolean;

  @Prop({ required: true })
  assigned!: boolean;

  @Prop({ required: true })
  launched!: boolean;

  @Prop({ required: true })
  terminated!: boolean;

  @Prop({ required: true })
  canceledByAnalyst!: boolean;

  getStatusDisplay(status: MeasuringRunStatus) {
    return MEASURING_RUN_STATUSES_DISPLAY.get(status);
  }
}

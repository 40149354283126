
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";

import { EMPTY, SERVER_CONFIG } from "@/config/literals";

import { LITE_FEATURE, MeasuringRunResume, MeasuringRunStatus } from "@/domain";
import { ServerConfig, UserInfos } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";
import StatusElement from "@/components/Core/ExamReports/Status/StatusElement.vue";
import MeasuringRunActions from "@/components/Core/ExamReports/Dashboard/MeasuringRunActions.vue";

@Component({
  components: {
    StatusElement,
    MeasuringRunActions,
    Feature
  },
  data: () => {
    return {
      MeasuringRunStatus,
      LITE_FEATURE
    };
  },
  filters: {
    pretty(date: Date): string {
      return lightFormat(date, "dd/MM/yyyy");
    },
    prettyEmpty(controller: UserInfos | null): string {
      return controller != null ? controller.fullName : EMPTY;
    },
  },
})
export default class MeasuringRunsTable extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;
  
  @Prop({ required: true })
  runsResume!: MeasuringRunResume[];

  @Prop({ required: true })
  loading!: boolean;

  get headers() {
    return [
      {
        text: "N° DO",
        align: "center",
        sortable: true,
        value: "operationalFile.number",
      },
      {
        text: "N° RE / N° Session",
        align: "center",
        sortable: true,
        value: "examReportRun",
      },
      {
        text: "Site / Tranche",
        align: "center",
        sortable: true,
        value: "plantUnit",
      },
      {
        text: "Système",
        align: "center",
        sortable: true,
        value: "system.trigram",
      },
      {
        text: "Équipement / Repère Zone",
        align: "center",
        sortable: true,
        value: "equipmentZone",
      },
      {
        text: "Création RE",
        align: "center",
        sortable: true,
        value: "creation",
      },
      {
        text: "Contrôleur",
        align: "center",
        sortable: true,
        value: "controller",
      },
      {
        text: "Statut",
        align: "center",
        sortable: true,
        value: "status",
      },
      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
      },
    ];
  }
}


import { Component, Vue } from "vue-property-decorator";

import ZoneDashboard from "@/components/Core/ExamReports/Dashboard/ZoneDashboard.vue";

@Component({
  components: {
    ZoneDashboard,
  },
})
export default class ExamReportDashboard extends Vue {}

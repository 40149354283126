
import { Component, Inject, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { AssignControllersDTO, IMeasuringRunService } from "@/services";

import { MeasuringRunStatus } from "@/domain";
import { UserInfos } from "prometheus-synced-ui";

import ControllerAutocomplete from "@/components/General/Autocompletes/ControllerAutocomplete.vue";
import RunsAutocomplete from "@/components/General/Autocompletes/ExamReports/RunsAutocomplete.vue";

@Component({
  components: {
    ControllerAutocomplete,
    RunsAutocomplete,
  },
  data: () => {
    return {
      MeasuringRunStatus,
    };
  },
})
export default class AssignMultipleDialog extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  loading: boolean = false;
  runIds: string[] = [];
  userId: string | null = null;
  controller: UserInfos | null = null;

  async assign() {
    try {
      this.loading = true;
      if (this.userId != null && this.runIds.length > 0) {
        const payloads: AssignControllersDTO[] = _.map(this.runIds, (r) => {
          return {
            measuringRunId: r,
            controllerId: this.userId!,
          };
        });
        const runs = await this.measuringRunService.assignManyRuns(payloads);
        this.$emit("assigned", { runs: runs, controller: this.controller });
      }
    } finally {
      this.loading = false;
    }
  }
}


import { Component, Inject, InjectReactive, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S, SERVER_CONFIG } from "@/config/literals";

import {
  LITE_FEATURE,
  MeasuringRunInfos,
  MeasuringRunResume,
  MeasuringRunStatus,
} from "@/domain";
import { ServerConfig, UserInfos } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";
import Loading from "@/views/Loading.vue";
import MeasuringRunsTable from "@/components/Core/ExamReports/Dashboard/MeasuringRunsTable.vue";
import DashboardFilter from "@/components/Core/ExamReports/Dashboard/DashboardFilter.vue";
import AssignDialog from "@/components/Core/ExamReports/Dashboard/Dialogs/AssignDialog.vue";
import AssignMultipleDialog from "@/components/Core/ExamReports/Dashboard/Dialogs/AssignMultipleDialog.vue";

import { IMeasuringRunService } from "@/services";

@Component({
  components: {
    Loading,
    MeasuringRunsTable,
    DashboardFilter,
    AssignDialog,
    AssignMultipleDialog,
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ZoneDashboard extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  get filteredRuns() {
    return this.filterStatuses(
      this.filterController(this.filterZone(this.runsResume))
    );
  }

  fetching: boolean = true;
  updating: boolean = false;
  assignDialog: boolean = false;
  assignMultipleDialog: boolean = false;
  runsResume: MeasuringRunResume[] = [];

  powerPlantId: string | null = null;
  nuclearUnitId: string | null = null;
  systemId: string | null = null;
  created: boolean = false;
  assigned: boolean = false;
  launched: boolean = false;
  terminated: boolean = false;
  canceledByAnalyst: boolean = false;
  controllerId: string | null = null;
  runIdToAssign: string | null = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.fetching = true;
      this.runsResume = await this.measuringRunService.getResume();
    } finally {
      this.fetching = false;
    }
  }

  filterStatuses(runsResume: MeasuringRunResume[]): MeasuringRunResume[] {
    let runs = _.cloneDeep(runsResume);
    if (
      this.created ||
      this.assigned ||
      this.launched ||
      this.terminated ||
      this.canceledByAnalyst
    ) {
      if (!this.created) {
        runs = _.filter(runs, (r) => r.status != MeasuringRunStatus.created);
      }
      if (!this.assigned) {
        runs = _.filter(runs, (r) => r.status != MeasuringRunStatus.assigned);
      }
      if (!this.launched) {
        runs = _.filter(runs, (r) => r.status != MeasuringRunStatus.launched);
      }
      if (!this.terminated) {
        runs = _.filter(runs, (r) => r.status != MeasuringRunStatus.terminated);
      }
      if (!this.canceledByAnalyst) {
        runs = _.filter(
          runs,
          (r) => r.status != MeasuringRunStatus.canceledByAnalyst
        );
      }
    }
    return runs;
  }

  filterZone(runsResume: MeasuringRunResume[]): MeasuringRunResume[] {
    let runs = _.cloneDeep(runsResume);
    if (this.systemId) {
      runs = _.filter(
        this.runsResume,
        (f) =>
          f.powerPlant.id == this.powerPlantId &&
          f.nuclearUnit.id == this.nuclearUnitId &&
          f.system.id == this.systemId
      );
    } else if (this.nuclearUnitId) {
      runs = _.filter(
        this.runsResume,
        (f) =>
          f.powerPlant.id == this.powerPlantId &&
          f.nuclearUnit.id == this.nuclearUnitId
      );
    } else if (this.powerPlantId) {
      runs = _.filter(
        this.runsResume,
        (f) => f.powerPlant.id == this.powerPlantId
      );
    }
    return runs;
  }

  filterController(runsResume: MeasuringRunResume[]): MeasuringRunResume[] {
    let runs = _.cloneDeep(runsResume);
    if (this.controllerId) {
      runs = _.filter(
        runsResume,
        (f) => f.controller != null && f.controller.id == this.controllerId
      );
    }
    return runs;
  }

  async remove(runId: string) {
    try {
      this.updating = true;
      await this.measuringRunService.remove(runId);
      const index = _.findIndex(this.runsResume, (r) => r.id == runId);
      if (index != -1) {
        this.runsResume.splice(index, 1);
      }
    } finally {
      this.updating = false;
    }
  }

  async cancel(runId: string) {
    try {
      this.updating = true;
      const index = _.findIndex(this.runsResume, (r) => r.id == runId);
      if (index != -1) {
        const run = await this.measuringRunService.updateStatus(runId, {
          status: MeasuringRunStatus.canceledByAnalyst,
        });

        this.runsResume[index].status = run.status;
        this.runsResume[index].controller = run.controller;
      }
    } finally {
      this.updating = false;
    }
  }

  assign(runId: string) {
    this.runIdToAssign = runId;
    this.assignDialog = true;
  }

  updateAssignedRun(runId: string, controller: UserInfos) {
    const runIndex = _.findIndex(this.runsResume, (r) => r.id == runId);
    if (runIndex != -1) {
      this.runsResume[runIndex].controller = controller;
      this.runsResume[runIndex].status = MeasuringRunStatus.assigned;
    }
  }

  onAssigned(controller: UserInfos) {
    this.assignDialog = false;
    this.updateAssignedRun(this.runIdToAssign!, controller);
  }

  async onAssignedMultiple(payload: {
    runs: MeasuringRunInfos[];
    controller: UserInfos;
  }) {
    for (const r of payload.runs) {
      this.updateAssignedRun(r.id, payload.controller);
    }
    this.assignMultipleDialog = false;
  }
}


import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import PowerPlantAutocomplete from "@/components/General/Autocompletes/Localisation/PowerPlantAutocompleteWrapper.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/DependentNuclearUnitAutocomplete.vue";
import HydraulicSystemAutocomplete from "@/components/General/Autocompletes/Localisation/HydraulicSystemAutocomplete.vue";

@Component({
  components: {
    PowerPlantAutocomplete,
    NuclearUnitAutocomplete,
    HydraulicSystemAutocomplete,
  },
})
export default class ZoneFilter extends Vue {
  @Prop({ required: true })
  powerPlantId!: string | null;

  @Prop({ required: true })
  nuclearUnitId!: string | null;

  @Prop({ required: true })
  systemId!: string | null;

  updatePowerPlant(id: string | null) {
    this.$emit("update:system-id", null);
    this.$emit("update:nuclear-unit-id", null);
    this.$emit("update:power-plant-id", id);
  }

  updateNuclearUnit(id: string | null) {
    this.$emit("update:system-id", null);
    this.$emit("update:nuclear-unit-id", id);
  }

  reset() {
    this.$emit("update:power-plant-id", null);
    this.$emit("update:nuclear-unit-id", null);
    this.$emit("update:system-id", null);
  }
}


import { Component, Inject, Prop, Ref, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { IMeasuringRunService } from "@/services";

import { UserInfos } from "prometheus-synced-ui";

import ControllerAutocomplete from "@/components/General/Autocompletes/ControllerAutocomplete.vue";

@Component({
  components: {
    ControllerAutocomplete,
  },
})
export default class AssignDialog extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  runId!: string;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  userId: string | null = null;
  controller: UserInfos | null = null;
  loading: boolean = false;
  valid: boolean = false;

  async save() {
    try {
      this.loading = true;
      if (this.form.validate()) {
        await this.measuringRunService.assignManyRuns([
          {
            controllerId: this.userId!,
            measuringRunId: this.runId,
          },
        ]);
        this.$emit("assigned", this.controller);
      }
    } finally {
      this.loading = false;
    }
  }
}

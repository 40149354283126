
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import {
  IExamReportService,
  IMeasuringRunService,
  IOperationalFileService,
} from "@/services";

import {
  ExamReportInfos,
  MeasuringRunInfos,
  MeasuringRunStatus,
  OperationalFileInfos,
} from "@/domain";

@Component
export default class RunsAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string[];

  @Prop({ required: true, default: false })
  disabled!: boolean;

  @Prop({ required: false })
  filterStatus!: MeasuringRunStatus;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  get examReportService() {
    return this.container.resolve<IExamReportService>(S.EXAM_REPORT_SERVICE);
  }

  get operationalFileService() {
    return this.container.resolve<IOperationalFileService>(
      S.OPERATIONAL_FILE_SERVICE
    );
  }

  get filteredRuns() {
    const runs = _.filter(this.runs, (s) => s.status == this.filterStatus);
    return _.map(runs, (r) => {
      return {
        id: r.id,
        fileNumber: this.getFileName(r),
        examReportNumber: this.getExamReport(r),
      };
    });
  }

  runs: MeasuringRunInfos[] = [];
  examReports: ExamReportInfos[] = [];
  files: OperationalFileInfos[] = [];
  loading: boolean = true;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.runs = await this.measuringRunService.getMany({
        ids: [],
        assignablesOnly: true,
      });
      this.examReports = await this.examReportService.getMany({
        ids: [],
      });
      this.files = await this.operationalFileService.getMany({
        ids: [],
      });
    } finally {
      this.loading = false;
    }
  }

  getExamReport(run: MeasuringRunInfos) {
    const index = _.findIndex(
      this.examReports,
      (e) => e.id == run.examReportId
    );
    return this.examReports[index].number;
  }

  getFileName(run: MeasuringRunInfos) {
    const examReportIndex = _.findIndex(
      this.examReports,
      (e) => e.id == run.examReportId
    );

    const fileIndex = _.findIndex(
      this.files,
      (f) => f.id == this.examReports[examReportIndex].operationalFileId
    );

    return this.files[fileIndex].number;
  }

  remove(id: string) {
    this.$emit(
      "input",
      _.filter(this.value, (g) => g != id)
    );
  }

  filter(
    item: {
      id: string | null;
      examReportNumber: string;
      fileNumber: string;
    },
    queryText: string,
    itemText: string
  ) {
    const textOne = item.examReportNumber.toLowerCase();
    const textTwo = item.fileNumber.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }
}
